import React, { useState, useEffect } from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);


export default function WorkSection() {

  var db = firebase.firestore();

  
  const classes = useStyles();

  const [message, setMessage] = useState("")
  const [name, setName] = useState("")
  const [emailOrPhone, setEmailOrPhone] = useState("")
  const sendMessage = () => {
    if (message != "" && name != "" && emailOrPhone != "") {
    sendContactData()
    }else{
      alert("Please enter valid data")
    }
  }

  const sendContactData = () => {
    console.log("sendContactData called")
    db.collection("contactInfo").doc().set({
      name: name,
      mobileOrEmail: emailOrPhone,
      message: message,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })
      .then(function () {
        alert("Thank you. We received your message. We will respond to you shortly")
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Work with us</h2>
          <h4 className={classes.description}>
            Write a
            few lines about each one and contact us about any further
            collaboration. We will respond and get back to you in a couple of
            hours.
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  onChange={(e) => setName(e.target.value)}
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  onChange={(e) => setEmailOrPhone(e.target.value)}
                  labelText="Your Phone Number or Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <CustomInput
                onChange={(e) => setMessage(e.target.value)}
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button color="primary" onClick={() => sendMessage()}>Send Message</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
