import React, { useState, useEffect } from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import CoconutIcon from "assets/img/coconut.svg";
import CopraIcon from "assets/img/copra.svg";
import OilIcon from "assets/img/oil.svg";
import ShellIcon from "assets/img/shell.svg";
import PeanutOil from "assets/img/peanutoil.svg";
import SesameOil from "assets/img/sesame.svg";
import CowIcon from "assets/img/cow.svg";
import GoatIcon from "assets/img/goat.svg";
import ChickenIcon from "assets/img/chicken.svg";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import firebase from "firebase";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();

  const [marketRate, setMarketRate] = useState(0.00)
  const [updatedTime, setUpdatedTime] = useState("")

  var db = firebase.firestore();

  useEffect(() => {
    fetchData()
  }, [marketRate])

  const fetchData = () => {
    console.log("fetchcalled")
    db.collection("market").orderBy("createdAt", "desc").limit(1).get().then(function (doc) {      
      var firstDoc = doc.docs[0]
      if (firstDoc.exists) {

        var price = (Math.round(firstDoc.data().price * 100) / 100).toFixed(2);
        setMarketRate(price)
        var timestamp = firstDoc.data().createdAt
        var createdAt = timestamp.toDate()
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        var timeFormatter = {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        };
        var timeString = createdAt.toLocaleString('en-US', timeFormatter);
        var dateFormat = createdAt.getDate() + " " + (monthNames[createdAt.getMonth()]) + " " + createdAt.getFullYear() + ", " + timeString
        setUpdatedTime(dateFormat)


      } else {
        // doc.data() will be undefined in this case
        setMarketRate(0)
        console.log("No such document!");
      }
    }).catch(function (error) {
      setMarketRate(0)
      console.log("Error getting document:", error);
    });
  }

  return (
    <div className={classes.section}>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk product</h2>
          <h5 className={classes.description}>
          Nesam Traders, a family own business since 1993 with head office in Thisayanvilai Bazar, Tirunelveli, TamilNadu, India, is dedicated to sourcing and supplying the finest quality coconuts. We bring our customers organic and high-grade Coconut Oil, Coconut Shell, and Copra. We work together with our sources and have it cared for by dedicated quality control staff which ensures that all coconut is of the finest quality. We offer our Coconut and its by-product in both wholesale and retail
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Coconut"
              description="We can supply fresh coconuts of Indian origin for any demanded product quantity at a competitive rate regularly. Our Company is well known for wholesale suppliers and exporters of fresh coconuts and coconut products. The Company has all grades of coconuts in all maturities, we can supply to various parts of India as well as abroad."
              icon={CoconutIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Copra"
              description="We are engaged in offering copra that used in extracting oil and also used as a dry fruit. Our range of copra is known for its high nutritive values and quality. We are offering copra at reasonable rates and our coconut copra is of high quality and can be availed by our clients in big and small quantities."
              icon={CopraIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Coconut Oil"
              description="We are producing coconut oil of good quality with no adulteration. We produce coconut oil using sun-dried coconuts which gives high nutritional value, taste, smell, and flavor "
              icon={OilIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>

      </div>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Coconut Shell"
              description="Our organization is a good processor of Coconut Shell. These are very hard and are derived from natural coconuts. Used to make a variety of bowls, tumblers, and showpieces. We provide our customers with a wide variety of needs."
              icon={ShellIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Groundnut Oil"
              description="This oil is completely natural. The sweet flavor of the groundnuts enhances the taste of food cooked in it. Made from high-quality groundnuts, this oil improves HDL levels (healthy body cholesterol) and helps reduce the risk of heart disease."
              icon={PeanutOil}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Sesame Oil"
              description="This oil is obtained by processing quality sesame seeds, using traditional methods that help retain its rich sesame flavor. It is considered a healthy cooking medium because it is rich with well-balanced nutrients like protein, calcium, iron, and vitamins."
              icon={SesameOil}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>

      </div>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Dairy Farm"
              description="Dairy farming is one of our growing traditional businesses.The cows on our farm are grown in an organic way. The cows on our farm are provided with natural foods, which produce nutrition-rich milk. We supply good quality of cow milk to our local areas and town"
              icon={CowIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Goat Farm"
              description="Goat farming is also one of our businesses. The demand for mutton is increasing day by day, especially due to its nutritional value and taste. We have several types of rare goat varieties. The goats on our farm are grown in an organic way"
              icon={GoatIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Country Chicken Farm"
              description="Country Chicken Farming is one of our rapidly growing business. We supply organic country chicken eggs for an affordable price, We supply good quality organic country chicken meat."
              icon={ChickenIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>

      </div>
      <br></br><br></br><br></br><br></br><br></br>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>

          <CardHeader color="white"  >
            <h5 className={classes.marketDesc}> Coconut Rate / Kg </h5>

            <h4 className={classes.marketTitle}> ₹ </h4><h3 className={classes.marketTitle}> {marketRate} kg</h3>
            <br />
            <h6 className={classes.marketDesc}> {updatedTime} </h6>

          </CardHeader>

        </GridItem>
      </GridContainer>
    </div>
  );
}
