import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


import styles from "assets/jss/material-kit-react/views/components.js";
import LandingPage from "views/LandingPage/LandingPage.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
 
  return (
    <div>
    <LandingPage></LandingPage>
    </div>
  );
}
